import React, { useEffect, useRef } from "react";
import Unav from '../components/Unav';
import Footer from '../components/Footer';
import Chart from "chart.js/auto";
import { Link } from "react-router-dom";

const Dashboard = () => {
 
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    const xValues = ["Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const yValues = [100, 49, 44, 24, 15, 33, 44, 55, 66, 77, 88, 22];
    const barColors = ["red", "green", "blue", "orange", "brown", "pink", "maroon", "skyblue", "yellow", "gray", "magenta", "brown"];
    // Destroy the previous chart instance if it exists
    if (chartInstanceRef.current) {  
      chartInstanceRef.current.destroy();
    }
    // Create a new chart instance and store it in the ref
    chartInstanceRef.current = new Chart(ctx, {
      type: "bar",
      data: {
        labels: xValues,
        datasets: [{
          backgroundColor: barColors,
          data: yValues
        }]
      },
      options: {
        plugins: {
          legend: { display: false },
          title: {
            display: true,
            text: "FY 24-25"
          }
        }
      }
    });
  
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, []);
  return (
    <>
          <div className="row" >
          <Unav /> 
          <div className="col-lg-9 col-12">
            <div className="container mb-5">
            <div className='p-3 mt-2 mb-2'>
              <h3>Welcome Back !!</h3>
            </div>
            <div className="bg-white rounded p-3" >
              <h5>Society Variable Income</h5>
              <div className="row mb-2">
                <div className="col-lg-6 col-12">
                  <div className="row">
                    <div className="col-lg-5 col-12 mt-3">
                      <div className="card p-3" style={{ backgroundColor: '#ffe2e6' }}>
                        <a className="mt-2 mb-2" > <b>Balance</b></a>
                        <h4 className="mt-3"><i class="bi bi-currency-rupee"></i>1,626.30 </h4>
                        <a className="mt-3 mb-2">As on 23 Aug, 2024</a>
                        <button className="btn btn-primary btn-sm w-100 mt-4 mb-2">Today</button>
                      </div>
                    </div>
                    <div className="col-lg-7 col-12 mt-3">
                      <div className="card p-2" style={{ backgroundColor: '#dcf0fa' }}>
                        <div className="row mt-2">
                          <a> <b>Last 7 Days</b></a>
                          <div className="col-6"><i class="bi bi-currency-rupee"></i>1,624.00</div>
                          <div className="col-6 text-end"><i class="bi bi-currency-rupee"></i>1,634.00</div>
                          <div className="col-12">
                            <input type="range" className="w-100"></input>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <a> <b>Last 30 Days</b></a>
                          <div className="col-6"><i class="bi bi-currency-rupee"></i>1,363.55
                          </div>
                          <div className="col-6 text-end"><i class="bi bi-currency-rupee"></i> 1,794.00</div>
                          <div className="col-12">
                            <input type="range" className="w-100"></input>
                          </div>
                        </div>
                        <div class="row mt-2 mb-2">
                          <b><a>FY 23-24</a></b>
                          <div class="col-4">7,433.00</div>
                          <div class="col-8 text-end">1625.75 / 1626.60</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12 mt-3">
                  <div className="card p-1" style={{ backgroundColor: '#f4e8ff' }}>
                    <canvas id="myChart" ref={chartRef} className="w-100"></canvas>
                  </div>
                </div>
              </div>
              </div>
              <div className="bg-white rounded p-3 mt-4" >
              <h4> Report List </h4>
                  <div className="row">
                  <div className="col-lg-3 col-12 mt-2">
                 
                      <div className="card p-2 " style={{ backgroundColor: '#29b6f6' }}>
                      <a className="text-white"><b>Inbox</b> </a> 
                      <div className="row">
                        <div className="col-6">
                        <h3 className="text-white mt-1 "> 7 </h3>      
                        </div>
                        <div className="col-6">
                        <h3 className="text-center text-white "><i class="bi bi-chat-quote"></i> </h3>
                        </div>
                        </div>      
                        <Link to='/inbox' className="text-uppercase text-white te w-100">Total Notifications</Link>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12 mt-2">
                    <div className="card p-2 " style={{ backgroundColor: '#ef5350' }}>
                      <a className="text-white"><b>Communication Update</b> </a> 
                      <div className="row">
                        <div className="col-6">
                        <h3 className="text-white mt-1"> 7 </h3>      
                        </div>
                        <div className="col-6">
                        <h3 className="text-center text-white mt-1"><i class="bi bi-bell"></i></h3>
                        </div>
                        </div>      
                        <Link to='/communication-update' className="text-uppercase text-white te w-100">Total Notifications</Link>
                      </div>
                  
                    </div>
                    <div className="col-lg-3 col-12 mt-2">
                    <div className="card p-2 " style={{ backgroundColor: '#66bb6a' }}>
                      <a className="text-white"><b>Society Events</b> </a> 
                      <div className="row">
                        <div className="col-6">
                        <h3 className="text-white mt-1"> 4 </h3>      
                        </div>
                        <div className="col-6">
                        <h3 className="text-center text-white mt-1"><i class="bi bi-bell"></i></h3>
                        </div>
                        </div>      
                        <Link to='/society-events' className="text-uppercase text-white te w-100">Total Notifications</Link>
                      </div>
                  
                    </div>
                   
                    <div className="col-lg-3 col-12 mt-2">
                    <div className="card p-2 " style={{ backgroundColor: '#ffa500' }}>
                      <a className="text-white"><b>Society Rules & Regulations</b> </a> 
                      <div className="row">
                        <div className="col-6">
                        <h3 className="text-white mt-1"> 8 </h3>      
                        </div>
                        <div className="col-6">
                        <h3 className="text-center text-white mt-1"><i class="bi bi-bell"></i></h3>
                        </div>
                        </div>      
                        <Link to='/rules-regulations' className="text-uppercase text-white te w-100">Total Notifications</Link>
                      </div>
                    
                    </div>
                   
                
                  </div>
                </div>

                <div className="bg-white rounded p-3 mt-4" >
                 <h4> Quick Links </h4>
                  <div className="row">
                  <div className="col-lg-3 col-12 mt-2">
                 
                      <div className="card p-2 " style={{ backgroundColor: '#ff00ff' }}>
                      <div className="row">
                        <div className="col-12">
                        <h6 className="text-white mt-1 "> 5/100 </h6>      
                        </div>
                        <Link to='/complaint-management' className="text-uppercase text-white">View Complaint</Link>
                        </div>      
                        
                      </div>
                    </div>
                    <div className="col-lg-3 col-12 mt-2">
                    <div className="card p-2 " style={{ backgroundColor: '#5c5f68' }}>
                      <div className="row">
                        <div className="col-12">
                        <h6 className="text-white mt-1 "> 20/100 </h6>      
                        </div>
                        <Link to='/visitor-management' className="text-uppercase text-white ">View Visitors List</Link>
                        </div>    
                        </div>
                    </div>
                    <div className="col-lg-3 col-12 mt-2">
                    <div className="card p-2 " style={{ backgroundColor: '#c4b4a4' }}>
                    <div className="row">
                        <div className="col-12">
                        <h6 className="text-white mt-1 "> 7/100 </h6>      
                        </div>
                        <Link to='/bill-report' className="text-uppercase text-white ">Check Bill Report</Link>
                        </div>    
                    </div>
                    </div>
                    <div className="col-lg-3 col-12 mt-2">
                    <div className="card p-2 " style={{ backgroundColor: '#b5717c' }}>
                    <div className="row">
                        <div className="col-12">
                        <h6 className="text-white mt-1 "> 7/100 </h6>      
                        </div>
                        <Link to='/staff-report' className="text-uppercase text-white te w-100">Check Staff Report</Link>
                        </div>    
                    </div>
                  </div>
                </div>
                </div>

               
                </div>
                </div>
            </div>
        
          <Footer />
    </>
  );
}
export default Dashboard;
