import React from "react";
import { Link } from "react-router-dom";

const  Ucoffer =()=>{

    return (
      <>
      

       <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12 mt-3"> <h4 className="radiant">Offer & Discount By Categories</h4></div>
            <div className="col-lg-3 col-8 mt-3"><input type="text" placeholder="Search" className="form-control" /> </div>
            <div className="col-lg-1 col-4 mt-3">  <span className="btn btn-outline-success">Search</span></div>
          </div>
          <div className="row mt-4">
            <div className="col">
              <Link to='/home/offer' id="storea"> All Offers </Link>
              <Link to='/home/offer#Grocery' id="storea">Grocery</Link>
              <Link to='/home/offer#Food' id="storea">Food</Link>
              <Link to='/home/offer#Gaming' id="storea">Gaming</Link>
              <Link to='/home/offer#StoresNearYou' id="storea">Stores Near You</Link>
              <Link to='/home/offer#GroceryStores' id="storea"> Grocery Stores</Link>
              <Link to='/home/offer#Shopping' id="storea"> Shopping</Link>
              <Link to='/home/offer#LifestylePayCashback' id="storea">  Lifestyle Pay Cashback</Link>
              <Link to='/home/offer#Travel' id="storea"> Travel</Link>
              <Link to='/home/offer#Health' id="storea">Health</Link>
              <Link to='/home/offer#ShoppingStores' id="storea">Shopping  Stores</Link>
              <Link to='/home/offer#TopBrands' id="storea">Top Brands</Link>
              <Link to='/home/offer#Beauty&PersonalCare' id="storea">Beauty & Personal Care</Link>
              <Link to='/home/offer#CashbackNextDoor' id="storea"> Cashback Next Door</Link>
              <Link to='/home/offer#Entertainment' id="storea">  Entertainment</Link>
              <Link to='/home/offer#HumaraBrandistan' id="storea">Humara Brandistan</Link>
              <Link to='/home/offer#Cashback' id="storea">Cashback</Link>
              <Link to='/home/offer#Electronics' id="storea"> Electronics line</Link>
              <Link to='/home/offer#Deal&Gifting' id="storea">Deal & Gifting</Link>
              <Link to='/home/offer#UPIOffers' id="storea">UPI Offers</Link>
              <Link to='/home/offer#Recharge&Bills' id="storea"> Recharge & Bills</Link>
              <Link to='/home/offer#FundTransfer' id="storea">Fund Transfer</Link>
              <Link to='/home/offer#Essentials' id="storea">Essentials</Link>
              <Link to='/home/offer#FreakyFri-YaY' id="storea">Freaky Fri-YaY</Link>
              <Link to='/home/offer#InfiniteSavings' id="storea">Infinite Savings</Link>
              <Link to='/home/offer#Education' id="storea">Education</Link>
              <Link to='/home/offer#ZIPOffers' id="storea">ZIP Offers</Link>
              <Link to='/home/offer#HealthStores' id="storea">Health Stores</Link>
              <Link to='/home/offer#SuperChoice' id="storea">SuperChoice</Link>
              <Link to='/home/offer#Restaurants' id="storea">Restaurants</Link>
              <Link to='/home/offer#Services' id="storea"> Services</Link>
              <Link to='/home/offer#CashbackOnZIP' id="storea">CashbackOnZIP</Link>
              <Link to='/home/offer#BoxOffice' id="storea">Box Office</Link>
              <Link to='/home/offer#TataNeuStore' id="storea">Tata Neu Store</Link>
            </div>

          </div>
        </div>
      
     
      </>
    )
   }
   export default Ucoffer;