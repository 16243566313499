import React, { useState } from "react";
import Unav from "../components/Unav";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import Api from "../components/Api";
function Updatepwd() {
  
  const userDetails = JSON.parse(localStorage.getItem('userDetails')) || { phone: '' };
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone] = useState(userDetails.phone); // Assuming phone is stored in localStorage
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("New password and confirm password do not match.");
      return;
    }
    try {
      const response = await Api.post("/change-pwd", {
        phone: phone,
        current_password: currentPassword,
        password: newPassword,
      });
      if (response.data.success) {
        setSuccess("Password updated successfully!");
        setError(""); // Clear any previous errors
      } else {
        setError(response.data.message || "Error updating password.");
        setSuccess(""); // Clear any previous success messages
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
      setSuccess(""); // Clear any previous success messages
    }
  };
  return (
    <>
     
      <div className="row">
        <Unav />
        <div className="col-lg-9 col-12">
          <div className="row">
            <div className="col-lg-3 col-6 bg-primary" id="alink">
              <Link to="/my-account" className="text-white">Update Your Profile</Link>
            </div>
            <div className="col-lg-3 col-6 bg-success" id="alink">
              <Link to="/change-password" className="text-white">Change Password</Link>
            </div>
          </div>
          <div className="row mt-2 me-2 ms-1">
            <div className="col-12 card p-3 mb-4">
              <div className="row">
                <div className="col-12">
                  <h5 className="radiant">Change Password </h5> <hr />
                </div>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-8 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="current-password"><b>Old Password</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input
                          className="form-control"
                          type="password"
                          id="current-password"
                          placeholder="Old Password"
                          value={currentPassword}
                          onChange={(e) => setCurrentPassword(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="new-password"><b>New Password</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input
                          className="form-control"
                          type="password"
                          id="new-password"
                          placeholder="New Password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-12 mt-3 mb-4 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="confirm-password"><b>Confirm Password</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input
                          className="form-control"
                          type="password"
                          id="confirm-password"
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-end">
                    <div className="col-lg-3 col-12 mb-3">
                      <input className="bg-primary text-white form-control me-5" type="submit" value="Submit" />
                    </div>
                  </div>
                  {error && <div className="alert alert-danger text-center p-2 mt-2  ">{error}</div>}
                  {success && <div className="alert alert-success text-center p-2  mt-2 ">{success}</div>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Updatepwd;
