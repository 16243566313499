import React from "react";
import { Link } from "react-router-dom";

const Coffer = () => {
  return (

    <>

      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-12 mt-3"> <h4 className="radiant">Offer & Discount By Categories</h4></div>
          <div className="col-lg-3 col-8 mt-3"><input type="text" placeholder="Search" className="form-control" /> </div>
          <div className="col-lg-1 col-4 mt-3"><span className="btn btn-outline-success">Search</span></div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <Link to='/offer' id="storea"> All Offers </Link>
            <Link to='/offer#Grocery' id="storea">Grocery</Link>
            <Link to='/offer#Food' id="storea">Food</Link>
            <Link to='/offer#Gaming' id="storea">Gaming</Link>
            <Link to='/offer#StoresNearYou' id="storea">Stores Near You</Link>
            <Link to='/offer#GroceryStores' id="storea"> Grocery Stores</Link>
            <Link to='/offer#Shopping' id="storea"> Shopping</Link>
            <Link to='/offer#LifestylePayCashback' id="storea">  Lifestyle Pay Cashback</Link>
            <Link to='/offer#Travel' id="storea"> Travel</Link>
            <Link to='/offer#Health' id="storea">Health</Link>
            <Link to='/offer#ShoppingStores' id="storea">Shopping  Stores</Link>
            <Link to='/offer#TopBrands' id="storea">Top Brands</Link>
            <Link to='/offer#Beauty&PersonalCare' id="storea">Beauty & Personal Care</Link>
            <Link to='/offer#CashbackNextDoor' id="storea"> Cashback Next Door</Link>
            <Link to='/offer#Entertainment' id="storea">  Entertainment</Link>
            <Link to='/offer#HumaraBrandistan' id="storea">Humara Brandistan</Link>
            <Link to='/offer#Cashback' id="storea">Cashback</Link>
            <Link to='/offer#Electronics' id="storea"> Electronics line</Link>
            <Link to='/offer#Deal&Gifting' id="storea">Deal & Gifting</Link>
            <Link to='/offer#UPIOffers' id="storea">UPI Offers</Link>
            <Link to='/offer#Recharge&Bills' id="storea"> Recharge & Bills</Link>
            <Link to='/offer#FundTransfer' id="storea">Fund Transfer</Link>
            <Link to='/offer#Essentials' id="storea">Essentials</Link>
            <Link to='/offer#FreakyFri-YaY' id="storea">Freaky Fri-YaY</Link>
            <Link to='/offer#InfiniteSavings' id="storea">Infinite Savings</Link>
            <Link to='/offer#Education' id="storea">Education</Link>
            <Link to='/offer#ZIPOffers' id="storea">ZIP Offers</Link>
            <Link to='/offer#HealthStores' id="storea">Health Stores</Link>
            <Link to='/offer#SuperChoice' id="storea">SuperChoice</Link>
            <Link to='/offer#Restaurants' id="storea">Restaurants</Link>
            <Link to='/offer#Services' id="storea"> Services</Link>
            <Link to='/offer#CashbackOnZIP' id="storea">CashbackOnZIP</Link>
            <Link to='/offer#BoxOffice' id="storea">Box Office</Link>
            <Link to='/offer#TataNeuStore' id="storea">Tata Neu Store</Link>
          </div>

        </div>
      </div>


    </>
  )
}

export default Coffer;

