
import Unav from "../components/Unav";
import Footer from "../components/Footer";
import React, { useState, useEffect } from 'react';

const Report = () => {
  const [reportType, setReportType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // Function to handle the form submission
  const handleGenerateReport = (e) => {
    e.preventDefault();
    // Here you can add logic to fetch the report based on the reportType, startDate, and endDate
    console.log('Report For:', reportType);
    console.log('Start Date:', startDate);
    console.log('End Date:', endDate);
  };
  return (

    <>
    
   
      <div className="row">
        <Unav />
            <div className="col-lg-9 col-12">
            <div className="row mt-2 me-2 ms-1">
              <div className="col-12  p-3 mb-4"> 
              <div className="container">
            <h2 className="radiant">Bill Report</h2>
            <hr />
            
     

      <form onSubmit={handleGenerateReport}>
        <div className="row mt-4 mb-5">
          <div className="col-md-3">
            <label htmlFor="reportFor" className="form-label text-dark">Report For</label>
            <select 
              id="reportFor" 
              className="form-select" 
              value={reportType} 
              onChange={(e) => setReportType(e.target.value)} 
              required
            >
              <option value="">Select</option>
              <option value="All">All</option>
              <option value="Bill">Bill</option>
              <option value="Complaint">Complaint</option>
              <option value="Visitor">Visitor</option>
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="startDate"  className="form-label text-dark">Start Date</label>
            <input 
              type="date" 
              id="startDate" 
              className="form-control" 
              value={startDate} 
              onChange={(e) => setStartDate(e.target.value)} 
              required 
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="endDate"  className="form-label text-dark">End Date</label>
            <input 
              type="date" 
              id="endDate" 
              className="form-control" 
              value={endDate} 
              onChange={(e) => setEndDate(e.target.value)} 
              required 
            />
          </div>
          <div className="col-md-3 d-flex align-items-end">
            <button type="submit" className="btn btn-primary ">Generate Report</button>
          </div>
        </div>
      </form>
    </div>
                 </div>
              </div>

          
             </div>
         </div>

    <Footer />
    </>
  );
}

export default Report;
