import React from "react";

const Landmarks = () => {
  return (
    <>
      <section>
        <div className="container mt-1">
          <div className="row">
            <div className="col-12 ">
              <h2 className=" ms-2 radiant mt-4 mb-3">Landmarks Near
                Amrapali Zodiac </h2>
            </div>
          </div>
          <div className="row" id="hos">
            <div className="col-lg-7 col-12">
              <div className="row">
                <div className="col-6 mt-4">
                  <div className="box p-2">
                    <h5 className="text-center"> Hospital </h5>
                    <hr />
                    <a href="https://www.felixhospital.com/">1. &nbsp; Felix Healthcare </a>
                    <hr />
                    <a href data-bs-toggle="modal" data-bs-target="#exampleMod" >2. &nbsp; Yatharth
                      Hospital... <span className="text-danger"><b>See All</b></span></a>
                  </div>
                </div>
                {/* modelpopup hospital */}
                <div className="modal fade" id="exampleMod" tabIndex={-1} aria-labelledby="exampleMod" aria-hidden="true">
                  <div className="modal-dialog ">
                    <div className="modal-content">
                      <div className="modal-header bg-info">
                        <h4 className="radiant" id="exampleMod">Hospital</h4>
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" aria-label="Close"> <b>Close</b></button>
                      </div>
                      <div className="model-body">
                        <ul class>
                          <a className="dropdown-item" id="ah" href="https://www.felixhospital.com/">
                            1. Felix Healthcare </a>
                          <a className="dropdown-item" id="ah" href>
                            2. Yatharth Hospital </a>
                          <a className="dropdown-item" id="ah" href="https://arogyawomenchildclinic.com/">
                            3. Arogya Clinic</a>
                          <a className="dropdown-item" id="ah" href="https://www.medindia.net/patients/hospital_search/j-s-tomar-memorial-hospital-pvt-ltd-noida-gautam-buddha-nagar-uttar-pradesh-21662-1.htm">
                            4. J S Tomar Memorial Hospital Pvt. </a>
                          <a className="dropdown-item" id="ah" href="https://www.practo.com/noida/clinic/maheshwari-clinic-noida-sector-108">
                            5. Maheshwari Clinic</a>
                          <a className="dropdown-item" id="ah" href="https://www.practo.com/noida/hospital/ganpati-hospital-salarpur-kalan">
                            6. Ganpati Hospitaland Maternity Centre</a>
                          <a className="dropdown-item" id="ah" href="https://www.thecompanycheck.com/org/shiv-health-care/fa71819978">
                            7. Shiv Clinic Gautam Buddha Nagar</a>
                          <a className="dropdown-item" id="ah" href>
                            8. Tanvi Clinic And Diagnostic</a>
                          <a className="dropdown-item" id="ah" href>
                            9. Mehta Clinic</a>
                          <a className="dropdown-item" id="ah" href>
                            10. Ojjus Hospital</a>
                          <a className="dropdown-item" id="ah" href="https://www.medindia.net/patients/hospital_search/r-n-clinic-noida-uttar-pradesh-89160-1.htm#google_vignette">
                            11. R.N. Clinic</a>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 mt-4">
                  <div className="box p-2">
                    <h5 className="text-center">Educational Institute
                    </h5>
                    <hr />
                    <a href="https://sksworldschool.com/">1. &nbsp; S.K.S School Noida </a>
                    <hr />
                    <a href data-bs-toggle="modal" data-bs-target="#exampleMo">2. &nbsp; Sardha
                      University...<span className="text-danger"><b>See All</b></span></a>
                  </div>
                </div>
                <div className="modal fade" id="exampleMo" tabIndex={-1} aria-labelledby="exampleMo" aria-hidden="true">
                  <div className="modal-dialog ">
                    <div className="modal-content">
                      <div className="modal-header bg-info">
                        <h4 className="modal-title radiant" id="exampleMod">Educational Institute</h4>
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" aria-label="Close"> <b>Close</b></button>
                      </div>
                      <div className="model-body">
                        <ul>
                          <a href="https://sksworldschool.com/" className="dropdown-item" id="ah" >1. S.K.S School Noida </a>
            
                          <a href className="dropdown-item" id="ah" >2. Sardha University </a>
                           
                          <a href className="dropdown-item" id="ah">3. KKMII Stratford
                            University</a>
                          <a href className="dropdown-item" id="ah">4. km Mayur School.</a>
                          <a href className="dropdown-item" id="ah">5. Amity University Noida</a>
                          <a href className="dropdown-item" id="ah">6. Government Post Graduate
                            College Noida</a>
                          <a href className="dropdown-item" id="ah">7. Amity University Greater
                            Noida</a>
                          <a href className="dropdown-item" id="ah">8. Delhi Technical Campus</a>
                          <a href className="dropdown-item" id="ah">9. Dronacharya Group of
                            Institutions</a>
                          <a href className="dropdown-item" id="ah">10.Accurate Institute of
                            Technology</a>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 mt-4">
                  <div className="box p-2">
                    <h5 className="text-center">Transportation Hub
                    </h5>
                    <hr />
                    <a href>1. &nbsp; Noida Sec. 137 Metro Station</a>
                    <hr />
                    <a href data-bs-toggle="modal" data-bs-target="#Transportation">2. &nbsp; Noida
                      sector 142 metro...<span className="text-danger"><b>See All</b></span></a>
                  </div>
                </div>
                <div className="modal fade" id="Transportation" tabIndex={-1} aria-labelledby="Transportation" aria-hidden="true">
                  <div className="modal-dialog ">
                    <div className="modal-content">
                      <div className="modal-header bg-info">
                        <h4 className="modal-title radiant" id="Transportation">Transportation Hub</h4>
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" aria-label="Close"> <b>Close</b></button>
                      </div>
                      <div className="model-body">
                        <ul>
                        <a href className="dropdown-item" id="ah">1. Noida Sec. 137 Metro Station</a>
                        <a href className="dropdown-item" id="ah">2. Noida
                        sector 142 metro</a>

                          <a href className="dropdown-item" id="ah">3. Noida sector 143 metro
                            station</a>
                          <a href className="dropdown-item" id="ah">4.Noida sector 83 metro
                            station</a>
                          <a href className="dropdown-item" id="ah">5. Noida sector 144 metro
                            station</a>
                          <a href className="dropdown-item" id="ah">6. Nsez metro station</a>
                          <a href className="dropdown-item" id="ah">7. Noida sector 145 metro
                            station</a>
                          <a href className="dropdown-item" id="ah">8. Noida sector 81 metro
                            station</a>
                          <a href className="dropdown-item" id="ah">9. Noida sector 146 metro
                            station</a>
                          <a href className="dropdown-item" id="ah">10.Noida sector 101 metro
                            station</a>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 mt-4">
                  <div className="box p-2">
                    <h5 className="text-center">Shopping Centre
                    </h5>
                    <hr />
                    <a href>1. &nbsp; Ganga Shopping Complex </a>
                    <hr />
                    <a  data-bs-toggle="modal" data-bs-target="#examplesh">2. &nbsp; Nx One
                      Mall...<span className="text-danger"><b>See All</b></span></a>
                  </div>
                </div>
                <div className="modal fade" id="examplesh" tabIndex={-1} aria-labelledby="examplesh" aria-hidden="true">
                  <div className="modal-dialog ">
                    <div className="modal-content">
                      <div className="modal-header bg-info">
                        <h4 className="modal-title radiant" id="examplesh">Shopping Centre</h4>
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" aria-label="Close"> <b>Close</b></button>
                      </div>
                      <div className="model-body">
                        <ul>
                        <a href className="dropdown-item" id="ah">1. Ganga Shopping Complex 
                        </a>
                        <a href className="dropdown-item" id="ah">2. Nx One
                        Mall
                       </a>
                          <a href className="dropdown-item" id="ah">3. Vishal Mega Mart
                            </a>
                          <a href className="dropdown-item" id="ah">4. Lotus Panache Shopping
                            Complex.</a>
                          <a href className="dropdown-item" id="ah">5. Mini CP Noida</a>

                          <a href className="dropdown-item" id="ah">6. V2 Mall
                           </a>
                          <a href className="dropdown-item" id="ah">7. Logic Mall
                            Noida</a>
                          <a href className="dropdown-item" id="ah">8. Ten Dimensions</a>
                          <a href className="dropdown-item" id="ah">9. Saya Group</a>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 mt-4">
                  <div className="box p-2">
                    <h5 className="text-center">Commute
                    </h5>
                    <hr />
                    <a href>1. &nbsp; Cognizant </a>
                    <hr />
                    <a  data-bs-toggle="modal" data-bs-target="#exampleS">2. &nbsp; Genpact...... <span className="text-danger"><b>See
                      All</b> </span></a>
                  </div>
                </div>
                <div className="modal fade" id="exampleS" tabIndex={-1} aria-labelledby="exampleS" aria-hidden="true">
                  <div className="modal-dialog ">
                    <div className="modal-content">
                      <div className="modal-header bg-info">
                        <h4 className="modal-title radiant" id="exampleMod">Commute</h4>
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" aria-label="Close"> <b>Close</b></button>
                      </div>
                      <div className="model-body">
                        <ul>
                        <a href className="dropdown-item" id="ah">1. &nbsp; Cognizant </a>
                      
                        <a href className="dropdown-item" id="ah">2. &nbsp; Genpact </a> 
                        <a href className="dropdown-item" id="ah">3. &nbsp;  Bus: 08</a>
              
                        <a href className="dropdown-item" id="ah">4. &nbsp; Metro AQUA </a>
                        <a href className="dropdown-item" id="ah">5. &nbsp;  Talkatora Stadium</a>
                        <a href className="dropdown-item" id="ah">6. &nbsp; Dwarka Sec 21 Crossing Bharthal Chowk </a>
                        <a href className="dropdown-item" id="ah">7. &nbsp;  Poothkalan More,Noida Sector 50</a>
                        <a href className="dropdown-item" id="ah">8. &nbsp; Kakrola More </a>
                        <a href className="dropdown-item" id="ah">9. &nbsp;  Neeti Bagh</a>
                        <a href className="dropdown-item" id="ah">10. &nbsp;Mukherjee Nagar Xing  </a>

  
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 mt-4">
                  <div className="box p-2">
                    <h5 className="text-center">Restaurants
                    </h5>
                    <hr />
                    <a href>1. &nbsp; Domino's Pizza </a>
                    <hr />
                    <a href data-bs-toggle="modal" data-bs-target="#Restaurants">2. &nbsp; HCL
                      cafeteria...<span className="text-danger"><b>See All</b></span></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="Restaurants" tabIndex={-1} aria-labelledby="Restaurants" aria-hidden="true">
              <div className="modal-dialog ">
                <div className="modal-content">
                  <div className="modal-header bg-info">
                    <h4 className="modal-title radiant" id="Restaurants">Restaurants</h4>
                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" aria-label="Close"> <b>Close</b></button>
                  </div>
                  <div className="model-body">
                    <ul class>
                      <a className="dropdown-item" id="ah" href>
                        1. Domino's Pizza</a>
                      <a className="dropdown-item" id="ah" href>
                        2.HCL cafeteria</a>
                      <a className="dropdown-item" id="ah" href>
                        3. Food corner</a>
                      <a className="dropdown-item" id="ah" href>
                        4. McDonald's </a>
                      <a className="dropdown-item" id="ah" href>
                        5.Cafe Coffee Day</a>
                      <a className="dropdown-item" id="ah" href>
                        6. Nescafe</a>
                      <a className="dropdown-item" id="ah" href>
                        7. Coffee Day Express</a>
                      <a className="dropdown-item" id="ah" href>
                        8. Sagar Ratna</a>
                      <a className="dropdown-item" id="ah" href>
                        9. Red Chillies</a>
                      <a className="dropdown-item" id="ah" href>
                        10. Mustos Cafe </a>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-lg-5 col-12 mt-4 ">
              <div className="card p-3" style={{ backgroundColor: '#d4d3d3' }}>
                <a href><i className="bi bi-journal-text text-danger" /> &nbsp;<span style={{ color: 'black' }}>Bank &amp; ATM.... </span><span style={{ color: 'red' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;<b>+2 More...</b></span></a>
                <hr />
                <a href><i className="bi bi-journal-text text-danger" /> &nbsp;<span style={{ color: 'black' }}>Doctors Clinic ....</span> <span style={{ color: 'red' }}>
                  <b>+2
                    More...</b></span></a>
                <hr />
                <a href> <i className="bi bi-journal-text text-danger" /> &nbsp;<span style={{ color: 'black' }}> Best Jewellery Showrooms... </span><span style={{ color: 'red' }}> <b>+2 More...</b></span></a>
                <hr />
                <a href><i className="bi bi-journal-text text-danger" /> &nbsp;<span style={{ color: 'black' }}> <span> </span>Car Showroom
                  &amp; Service Center .... </span><span style={{ color: 'red' }}> <b>+2
                    More...</b></span></a>
                <hr />
                <a href> <i className="bi bi-journal-text text-danger" /> &nbsp;<span style={{ color: 'black' }}> Beauty &amp; Unisex Salon...</span> <span style={{ color: 'red' }}> <b>+2 More...</b></span></a>
                <hr />
                <a href><i className="bi bi-journal-text text-danger" /> &nbsp;<span style={{ color: 'black' }}>Chemist Shop .... </span><span style={{ color: 'red' }}>
                  <b>+2
                    More...</b></span></a>
                <hr />
                <a href> <i className="bi bi-journal-text text-danger" /> &nbsp;<span style={{ color: 'black' }}> Verified Property Dealers...</span> <span style={{ color: 'red' }}><b>+2 More...</b></span></a>
                <hr />
                <a href><i className="bi bi-journal-text text-danger" /> &nbsp;<span style={{ color: 'black' }}>Residential Or Commercial Property...</span> <span style={{ color: 'red' }}><b>+2 More...</b></span></a>
                <hr />
                <a href style={{ color: 'red' }}><b>See all</b> &nbsp;<i className="bi bi-arrow-right" /></a>
              </div>

            </div>


          </div>
        </div>

      </section>
    </>
  )

}
export default Landmarks;