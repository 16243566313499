import React from "react"
import Unav from "../components/Unav";
import Footer from "../components/Footer";


const Ustaff = () => {

  return (

    <>
    
   
      <div className="row">
        <Unav />
            <div className="col-lg-9 col-12">
      
             </div>
         </div>

    <Footer />
    </>
  );
}

export default Ustaff;
