import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Unav from "../components/Unav";
import Footer from "../components/Footer";
import Code from "../components/Code";
import Api from "../components/Api";
function Uprofile() {
  const [message, setMessage] = useState(null);
  const [formData, setFormData] = useState({
    role: '',
    name: '',
    phone: '',
    email: '',
    flat: '',
    tower: '',
    addressProof: '',
    accessCardNo: '',
    parkingNumber: '',
    parkingProof: '',
    parkingStickerNo: '',
    rfidTagNo: '',
    vehicleType: '',
    vehicleModel: '',
    drivingLicenceNo: '',
    licenceProof: '',
    petRegister: ''
  });
  const [progress, setProgress] = useState({
    addressProof: 0,
    parkingProof: 0,
    licenceProof: 0
  });
  // Fetch user data when the component mounts
  useEffect(() => {
    const fetchUserData = async () => {
      const phone = JSON.parse(localStorage.getItem('userDetails'))?.phone || '';
      if (phone) {
        try {
          const response = await Api.post(`/userinfo`, { phone });
          const result = response.data;
          if (result.message === "User Detail Fetch Successfully") {
            setFormData(result.userDetail);
          } else {
            setMessage('Failed to fetch user data.');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setMessage('An error occurred while fetching user data.');
        }
      }
    };
    fetchUserData();
  }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };
  const handleFileChange = (e, fieldName) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(prevData => ({ ...prevData, [fieldName]: file }));
      // Simulate file upload progress
      const uploadSimulation = setInterval(() => {
        setProgress(prevProgress => {
          const newProgress = { ...prevProgress };
          if (newProgress[fieldName] >= 100) {
            clearInterval(uploadSimulation);
            return { ...newProgress, [fieldName]: 100 };
          }
          newProgress[fieldName] += 10;
          return newProgress;
        });
      }, 300);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formDataToSend = new FormData();
    Object.keys(formData).forEach(key => {
      if (formData[key]) {
        formDataToSend.append(key, formData[key]);
      }
    });
  
    try {
      const response = await Api.post('/updateprofile', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      // Directly using the API response message
      const result = response.data;
      setMessage(result.message);
    } catch (error) {
      console.error('Error updating profile:', error);
      // Displaying error message from API if available, or fallback message
      setMessage(error.response?.data?.message || 'An error occurred while updating your profile.');
    }
  };
  
  const renderFileUploadSection = (label, id, fieldName) => (
    <div className="col-lg-6 col-12 mt-3 form-group">
      <div className="row">
        <div className="col-lg-6 col-12 mt-2">
          <label htmlFor={id}><b>{label}</b></label>
        </div>
        <div className="col-lg-6 col-12 mt-2">
          <input
            className="form-control w-100"
            type="file"
            id={id}
            name={id}
            onChange={(e) => handleFileChange(e, fieldName)}
          />
        </div>
      </div>
      <div className="progress mt-1">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${progress[fieldName]}%` }}
          aria-valuenow={progress[fieldName]}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {progress[fieldName]}%
        </div>
      </div>
    </div>
  );
  return (
    <>
      
      <div className="row">
        <Unav />
        <div className="col-lg-9 col-12">
          <div className="row">
            <div className="col-lg-3 col-6 bg-primary" id="alink">
              <Link to="/my-account" className="text-white">Update Your Profile</Link>
            </div>
            <div className="col-lg-3 col-6 bg-success" id="alink">
              <Link to="/change-password" className="text-white">Change Password</Link>
            </div>
          </div>
          <div className="row mt-2 me-2 ms-1">
            <form onSubmit={handleSubmit}>
              <div className="col-12 card p-3 mb-4">
                <div className="row">
                  <div className="col-12">
                    <h5 className="radiant">Update Your Profile </h5> <hr />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label><b>I am an/a</b> <span className="text-danger">*</span>  </label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input type="text" className="w-100 form-control text-uppercase" name="role" value={formData.role} onChange={handleInputChange} required />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="account-fn"><b>Name</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input
                          className="w-100 form-control text-uppercase"
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="account-phone"><b>Phone</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <div className="row">
                          <div className="col-4">
                            <Code disabled />
                          </div>
                          <div className="col-8">
                            <input
                              className="w-100 form-control text-uppercase"
                              type="text"
                              name="phone"
                              value={formData.phone}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="account-email"><b>E-Mail ID:</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input
                          className="form-control text-uppercase"
                          type="text"
                          id="account-email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label><b>Tower</b></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input
                          className="form-control w-100 text-uppercase"
                          type="text"
                          name="tower"
                          value={formData.tower}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label><b>Flat No.</b></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input
                          className="form-control w-100 text-uppercase"
                          type="text"
                          name="flat"
                          value={formData.flat}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  {renderFileUploadSection('Proof of Address', 'addressProofAttachment', 'addressProof')}
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="accessCard"><b>Access Card</b></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input
                          className="form-control"
                          type="text"
                          id="accessCard"
                          name="accessCardNo"
                          value={formData.access_cardno}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-6 col-12 mt-2">
                        <label htmlFor="parkingNumber"><b>Parking Number</b></label>
                      </div>
                      <div className="col-lg-6 col-12 mt-2">
                        <input
                          className="form-control text-uppercase"
                          type="text"
                          id="parkingNumber"
                          name="parkingNumber"
                          value={formData.parking_number}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  {renderFileUploadSection('Proof of Parking No.', 'parkingNumberAttachment', 'parkingProof')}
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-6 col-12 mt-2">
                        <label htmlFor="parkingSticker"><b>Parking Sticker issued</b> </label>
                      </div>
                      <div className="col-lg-6 col-12 mt-2">
                        <input
                          className="form-control"
                          type="text"
                          id="parkingSticker"
                          name="parkingStickerNo"
                          value={formData.parking_sticker_number}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-6 col-12 mt-2">
                        <label htmlFor="rfidTag"><b>RFID TAG [Boom Barrier]</b> </label>
                      </div>
                      <div className="col-lg-6 col-12 mt-2">
                        <input
                          className="form-control"
                          type="text"
                          id="rfidTag"
                          name="rfidTagNo"
                          value={formData.rfid}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-6 col-12 mt-2">
                        <label htmlFor="vehicleType"><b>Vehicle Type</b> </label>
                      </div>
                      <div className="col-lg-6 col-12 mt-2">
                        <select
                          name="vehicleType"
                          id="vehicleType"
                          className="form-select"
                          value={formData.vehicle_type }
                         
                          onChange={handleInputChange}
                        >
                          <option value="">Select Vehicle Type</option>
                          <option value="2 Wheeler">2 Wheeler</option>
                          <option value="4 Wheeler">4 Wheeler</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-6 col-12 mt-2">
                        <label htmlFor="vehicleModel"><b>Vehicle Model</b> </label>
                      </div>
                      <div className="col-lg-6 col-12 mt-2">
                        <input
                          className="form-control"
                          type="text"
                          id="vehicleModel"
                          name="vehicleModel"
                          value={formData.vehicle_model_details}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3 form-group">
                    <div className="row">
                      <div className="col-lg-6 col-12 mt-2">
                        <label htmlFor="drivingLicence"><b>Driving Licence No</b> </label>
                      </div>
                      <div className="col-lg-6 col-12 mt-2">
                        <input
                          className="form-control"
                          type="text"
                          id="drivingLicence"
                          name="drivingLicenceNo"
                          value={formData.driving_licence_number}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  {renderFileUploadSection('Proof of Driving Licence No.', 'drivingLicenceAttachment', 'licenceProof')}
                 
                  <div className="col-lg-6 col-12 mt-1 form-group">
                    <div className="row">
                      <div className="col-lg-6 col-12 mt-2">
                        <label htmlFor="petRegistration"><b>Do you have pet registration before?</b> </label>
                      </div>
                      <div className="col-lg-6 col-12 mt-2">
                        <div className="dropdown">
                          <a className="form-control dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                            {formData.petRegister || 'Select...'}
                          </a>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <li><input type="text" className="dropdown-item text-dark" value="Yes" disabled /></li>
                            <li><Link to="/pet-register" className="dropdown-item">No</Link></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                  <div className="row justify-content-end mt-3">
                    <div className="col-lg-3 col-12 mb-3 ">
                      <input className="bg-primary text-white form-control text-uppercase w-100" type="submit" value="Submit" />
                    </div>
                  </div>
                  <div>
                  {message && (
                  <div className="alert alert-info  text-center mt-3 p-2" role="alert">
                    {message}
                  </div>
                )}
                  </div>
               
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Uprofile;
