import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Api from "./Api";
import Unavbar from "./Unavbar";

const Unav = () => {
  const [userDetail, setUserDetail] = useState({});
  const [message, setMessage] = useState('');
  useEffect(() => {
    const fetchUserData = async () => {
      const phone = JSON.parse(localStorage.getItem('userDetails'))?.phone || '';
      if (phone) {
        try {
          const response = await Api.post(`/userinfo`, { phone });
          const result = response.data;
          if (result.message === "User Detail Fetch Successfully") {
            setUserDetail(result.userDetail); // Correct the state update to setUserDetail
          } else {
            setMessage('Failed to fetch user data.');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setMessage('An error occurred while fetching user data.');
        }
      }
    };

    fetchUserData();
  }, []);

  return (
    <> 
    <Unavbar />
    <div className="col-12 p-5 bg-dark">
    </div>
    <div className="col-lg-3 col-12 bg-dark text-white">
      <div className="row text-white">
        <div className="col-4 ms-2 text-center">
          <img src="../assets/images/profile.png" alt="User-photo" className='w-75 rounded-circle' />
        </div>
        <div className="col-7">
          <div className="row text-center">
            <div className="col-6 text-uppercase">{userDetail.name }</div>
            <div className="col-6 text-uppercase">{userDetail.role }</div>
            <div className="col-6 text-uppercase mt-3">{userDetail.tower}</div>
            <div className="col-6 text-uppercase mt-3">{userDetail.flat }</div>
          </div>
        </div>
      </div>
      <hr />
      <div className="col-12 ms-3">
        <Link to="/my-account" className="list-group-item ms-2">
          <i className="bi bi-person"></i> &nbsp; &nbsp; My Account
        </Link>
        <hr />
        <nav className="ms-2">
          <strong>
            <Link to="/dashboard" className="text-white">
              <i className="bi bi-house-door"></i> &nbsp; &nbsp; Dashboard
            </Link>
          </strong>
          <hr />
          <Link to="/visitor-management" className="list-group-item">
          <i class="bi bi-people"></i> &nbsp; &nbsp; Visitor Management
          </Link>   <hr />
          <Link to="/complaint-management" className="list-group-item">
          <i class="bi bi-tools"></i> &nbsp; &nbsp; Complaint Management  
          </Link>   <hr />
          
          <li className="list-group-item" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
            <i className="bi bi-bell"></i> &nbsp; &nbsp;  Notifications <span style={{ marginLeft: '47%' }}> <b>+</b></span>
          </li>
          <div className="collapse" id="collapseExample">
            <div className="card card-body me-3 ms-0 text-dark">
              <Link to="/inbox" className="list-group-item">
                <i className="bi bi-bell"></i> &nbsp; &nbsp; Inbox
              </Link>
              <hr />
              <Link to="/communication-update" className="list-group-item">
                <i className="bi bi-journals"></i> &nbsp; &nbsp; Communication Update
              </Link>
              <hr />
              <Link to="/society-events" className="list-group-item">
                <i className="bi bi-journals"></i> &nbsp; &nbsp; Society Events
              </Link>
              <hr />
              <Link to="/rules-regulations" className="list-group-item">
                <i className="bi bi-journals"></i> &nbsp; &nbsp; Society Rules & Regulations<br /> &nbsp; &nbsp; &nbsp; &nbsp; For Residents
              </Link>
              <hr />
            </div>
          </div>
          <hr />
          <li className="list-group-item" data-bs-toggle="collapse" href="#collapseExample2" role="button" aria-expanded="false" aria-controls="collapseExample2">
            <i className="bi bi-bookmark-plus"></i> &nbsp; &nbsp; Request & Approvals <span style={{ marginLeft: '30%' }}> <b>+</b></span>
          </li>
          <div className="collapse mt-2" id="collapseExample2">
            <div className="card me-3 ms-0">
              <Link to="/access-card" className="list-group-item bg-success p-3 text-white">
                <i className="bi bi-credit-card-2-back"></i> &nbsp; &nbsp; Apply Access card
              </Link>
              <Link to="/rfid-tag" className="list-group-item bg-primary p-3 text-white">
                <i className="bi bi-tags"></i> &nbsp; &nbsp; Apply RFID TAG [Boom Barrier]
              </Link>
              <Link to="/parking-sticker" className="list-group-item bg-danger p-3 text-white">
                <i className="bi bi-p-circle"></i> &nbsp; &nbsp; Apply Parking Sticker
              </Link>
              <Link to="/pet-register" className="list-group-item bg-info p-3 text-white">
                <i className="bi bi-file-earmark-plus"></i> &nbsp; &nbsp; Register Your Pet
              </Link>
              <Link to="/booking-society-area" className="list-group-item bg-warning p-2 text-white">
                <i className="bi bi-file-earmark-plus"></i> &nbsp; &nbsp; Book Resources & Common<br /> &nbsp; &nbsp; &nbsp; &nbsp; Place e.g Banquet hall... etc.
              </Link>
            </div>
          </div>
          <hr />
          <Link to="/bill-report" className="list-group-item">
          <i className="bi bi-person-workspace"></i> &nbsp; &nbsp; Bill & Report
          </Link>
          <hr />
          <Link to="/staff-report" className="list-group-item">
            <i className="bi bi-person-workspace"></i> &nbsp; &nbsp; Daily Staff Report
          </Link>   
          <hr />
          <Link to="/voting" className="list-group-item">
            <i className="bi bi-badge-vo"></i> &nbsp; &nbsp; Online Voting | Make a Poll in Minutes
          </Link>
          <hr />
          <Link to="/exit-management" className="list-group-item">
            <i className="bi bi-box-arrow-right"></i> &nbsp; &nbsp; Exit Management
          </Link>
          <hr />
          <li className="list-group-item" data-bs-toggle="collapse" href="#collapseExample3" role="button" aria-expanded="false" aria-controls="collapseExample3">
            <i className="bi bi-bookmark-plus"></i> &nbsp; &nbsp; Bill Payment <span style={{ marginLeft: '50%' }}> <b>+</b></span>
          </li>
          <div className="collapse mt-2" id="collapseExample3">
            <div className="card me-3 ms-0">
              <Link to="/bill-payment" className="list-group-item bg-success p-3 text-white">
                <i className="bi bi-credit-card-2-back"></i> &nbsp; &nbsp; Mobile & Broadband
              </Link>
              <Link to="/bill-payment" className="list-group-item bg-primary p-3 text-white">
                <i className="bi bi-tags"></i> &nbsp; &nbsp; Gas Pipeline
              </Link>
              <Link to="/bill-payment" className="list-group-item bg-danger p-3 text-white">
                <i className="bi bi-p-circle"></i> &nbsp; &nbsp; LIC/Insurance
              </Link>
              <Link to="/bill-payment" className="list-group-item bg-info p-3 text-white">
                <i className="bi bi-file-earmark-plus"></i> &nbsp; &nbsp; Credit Card
              </Link>
              <Link to="/bill-payment" className="list-group-item bg-warning p-2 text-white">
                <i className="bi bi-file-earmark-plus"></i> &nbsp; &nbsp; Loan EMI
              </Link>
            </div>
          </div>
          <hr />
        </nav>
      </div>
    </div>
  
   
    </>
  );
};

export default Unav;
