import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Res from "../components/Res";
import Navbar from "../components/Navbar";
import Option from "../components/Option";
import Coffer from "../components/Coffer";
import Animities from "../components/Animities";
import Landmarks from "../components/Landmarks";
import React, { useEffect, useState } from "react";
import Api from "../components/Api";
import { useNavigate } from "react-router-dom";
function Home() {
  const navigate = useNavigate();
  const [adminPosts, setAdminPosts] = useState([]);
  const [eventPosts, setEventPosts] = useState([]);
  const [rulesPosts, setrulesPosts] = useState([]);
  useEffect(() => {
    const fetchAdminPosts = async () => {
      try {
        const response = await Api.get("/communication-update-list");
        setAdminPosts(response.data.data);
      } catch (error) {
        console.error("Error fetching admin posts:", error);
      }
    };
    const fetchrulesPosts = async () => {
      try {
        const response = await Api.get("rules-regulations");
        setrulesPosts(response.data.data);
      } catch (error) {
        console.error("Error fetching rules posts:", error);
      }
    };
    const fetcheventPosts = async () => {
      try {
        const response = await Api.get("/event-list");
        setEventPosts(response.data.data);
      } catch (error) {
        console.error("Error fetching event posts:", error);
      }
    };
    // Fetch data from both APIs
    const fetchData = async () => {
      try {
        await Promise.all([fetchAdminPosts(), fetcheventPosts(), fetchrulesPosts()]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  const handlePostClick = (post) => {
    navigate('/rules-&-regulations', { state: { selectedPost: post } }); // Navigate to PostDetails page with state
  };
  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: "body{background: #f0ff793d" }} />
      <Navbar />
      {/* Video Section */}
      <header className="hero-section" id="section_1">
        <div className="section-overlay" />
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mt-5 ">
              <h5 className="text-white mt-3">HELLO RESIDENT
              </h5>
              <h3 className="text-white mt-4 text-uppercase"> WELCOME TO Amrapali Zodiac </h3>
              <p className="text-white">All Rights Reserved For Amrapali Zodiac  Residents [Owners &amp;
                Tenants], <br />
                Offers and discounts are valid only for society residents.</p>
              <Link to="/login" className="custom-btn mb-5"> JOIN US
                NOW!</Link>
            </div>
          </div>
        </div>
        <div className="video-wrap">
          <video autoPlay loop muted className="custom-video">
            <source src="assets/video.mp4" type="video/mp4" />
          </video>
        </div>
      </header>
      {/* Access Card */}
      <section className="about-section " id="section_2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-sm-12 mt-5 mb-5">
              <div className="team-style1 text-center">
                <img src="assets/images/a.jpg" className="border-radius-5 rounded " alt="..." id="imgcard" />
                <div className="team-info">
                  <Link to="/login" className="btn btn-outline-danger mt-2" id="btn4"> <b>
                    Apply Access Card </b></Link>
                  <p className="text-white mt-1"> Security is important for us </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12 mt-5 mb-5">
              <div className="team-style1 text-center">
                <img src="assets/images/p.jpg" className="border-radius-5 rounded" alt="..." id="imgcard" />
                <div className="team-info">
                  <Link to="/login" className="btn btn-outline-danger mt-2" id="btn4">
                    <b>Apply RFID TAG [Boom Barrier] </b></Link>
                  <p className="text-white mt-1">Also, Request For Parking Sticker
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12 mt-5 mb-5">
              <div className="team-style1 text-center">
                <img src="assets/images/pet.jpg" className="border-radius-5 rounded " alt="..." id="imgcard" />
                <div className="team-info">
                  < Link to="/Login" className="btn btn-outline-danger mt-2" id="btn4">
                    <b>Register Now</b></Link>
                  <p className="text-white mt-1 ">We are Pet friendly, Your pet is Welcome
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12 mt-5 mb-5">
              <div className="team-style1 text-center">
                <img src="assets/images/hall2.png" className="border-radius-5 rounded " alt="..." id="imgcard" />
                <div className="team-info">
                  <a href data-bs-toggle="modal" data-bs-target="#exampleModal" className="btn btn-outline-danger mt-2" id="btn4"> <b>Book Your Place</b></a>
                  <p className="text-white mt-1 ">Resources & Amenities
                    Booking
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* popup form */}
      <Option />
      {/* Lifestyle Amenities */}
      <Animities />
      {/* Society Rules & Regulations */}
      <section>
        <div className="container mt-4">
          <div className="row">
            <div className="col-12 ">
              <h2 className="radiant mb-3"> Society Rules &amp; Regulations For Residents</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12 mt-4">
              <div className="card p-3 ">
                {rulesPosts.map((post) => (
                  <a className="w-100" key={post.id} onClick={() => handlePostClick(post)}>
                    <span><i className="bi bi-journal-text text-danger" />  &nbsp;
                      {post.name} </span> <span className="text-danger">...Read More</span> <hr /> </a>
                ))}
                <Link to="dashboard" className="text-danger"> See all &nbsp;<i className="bi bi-arrow-right" /></Link>
              </div>
            </div>
            <div className="col-lg-6 col-12 mt-4 ">
              <div className="row">
                <div className="col-12">
                  <div className="card p-4" style={{ backgroundColor: '#d3d3d3' }}>
                    <h2 className="post-title">
                      <Link to="/gym-rules&regulations" className="article-link mb-1" >
                        GYM RULES &amp; REGULATIONS
                      </Link>
                    </h2>
                    <div className="summary" id="su">
                      All gym users must be properly attired in standard workout clothes i.e. Tank
                      tops, T-shirts, shorts, leotards with running or
                      cross training shoes (not use outside the gym) while in exercise area...
                    </div>
                    <div>
                      <Link to="/gym-rules&regulations" className="btn btn-danger mt-3"> Expolore Now</Link>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-4">
                  <div className="card p-4" style={{ backgroundColor: '#d3d3d3' }}>
                    <h2 className="post-title ">
                      <Link to="/swimming-pool-rules&regulations" className="article-link mb-1">
                        SWIMMING POOL RULES &amp; REGULATIONS
                      </Link>
                    </h2>
                    <div className="summary" id="su">
                      Swimming Pool Rules and Regulations in a Residential Society Come summer, a
                      society’s swimming pool is an
                      entertaining and vibrant hangout for children as well as adults...
                    </div>
                    <div>
                      <Link to="/swimming-pool-rules&regulations" className="btn btn-danger mt-3 ">Expolore Now</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Our socity events */}
      <section className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12 ">
              <h2 className="radiant text-center">Our Society Events...</h2>
            </div>
          </div>
          <div className="row mt-4 p-2" id="commupd">
            <div className="col-lg-4 popular-post card " style={{ backgroundColor: '#d3d3d3' }}>
              <div className="row mt-2 mb-4">
                <div className="col-12">
                  <h6 className="mt-4 mb-4 text-center"> COMMUNICATION UPDATE</h6>
                </div>
                <hr />
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      {adminPosts.map((post) => (
                        <Link to="/login" className="mb-3 mt-1 w-100">
                          <span> <i className="bi bi-journal-text text-danger" /> </span>   &nbsp;
                          {post.name}
                          <br /> <hr />
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4 mb-5">
                <div className="col-12">
                  <Link to="/login" className="btn btn-danger">ALL UPCOMING MEETINGS</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-8 ">
              {eventPosts.map((post) => (
                <article>
                  <div className="line-item hf-item-odd clearfix">
                    <div className="content-image">
                      <Link to='/login' className="image-link article-link">
                        <img className="rounded box" src={post.photo} alt="img" id="imgthum" />
                        <span className="overlay article-overlay" />
                      </Link>
                    </div>
                    <div className="hf-info">
                      <div className="hf-category">
                        <Link to='/login' className="text-danger">
                          {post.month}
                        </Link>
                      </div>
                      <h2 className="post-title">
                        <Link to='/login' className="article-link" >
                          {post.name}
                          <span className="overlay article-overlay" />
                        </Link>
                      </h2>
                      <div className="summary">
                        {post.description}
                      </div>
                      <Link to='/login' className="btn btn-danger mt-2">Read More</Link>
                    </div>
                  </div>
                </article>
              ))}
            </div>
            <div className="line-item hf-item-odd clearfix" />
          </div>
        </div>
      </section>
      {/* Landmarks Near Amrapali Zodiac  */}
      <Landmarks />
      {/* recharge */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12 text-center mt-5 ">
              <div className="card " style={{ backgroundColor: '#dcf0fa' }}>
                <div className="containerr ms-4">
                  <img src="assets/images/medcine.jpeg" alt="Avatar" className="medimg " />
                  <div className="middle2">
                    <a href="https://janaushadhi.gov.in/KendraDetails.aspx" target="_blank" rel="noopener noreferrer" className="text2 btn btn-danger">Know More</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-12 mt-5 justify-content-center">
              <div className="row">
                <div className="col-12">
                  <h4 className="radiant ms-3 " > Amazing Offers;
                    Recharge & pay Bills,
                    Get Upto 15% Cashback! </h4>
                </div>
              </div>
              <div class="row mt-2" id="Amenities">
                <div class="col-2" id="ic" style={{ backgroundColor: '#dcf0fa' }} >
                  <Link to="/login">
                    <img src="assets/images/icon/mobilere.png" alt="" width="40" class="mt-2" /> <br />
                    Mobile & Broadband </Link>
                </div>
                <div class="col-2" id="ic" style={{ backgroundColor: '#dcf0fa' }} >  <Link to="/login">
                  <img src="assets/images/icon/gas.png" alt="" width="40" class="mt-3 mb-2" />
                  <br /> Gas Pipeline </Link>
                </div>
                <div class="col-2" id="ic" style={{ backgroundColor: '#dcf0fa' }} >
                  <Link to="/login">
                    <img src="assets/images/icon/lic.png" alt="" width="40" class="mt-3 mb-2" /> <br />
                    LIC/Insurance </Link>
                </div>
                <div class="col-2" id="ic" style={{ backgroundColor: '#dcf0fa' }} >
                  <Link to="/login"><img src="assets/images/icon/card.png" alt="" width="40" class="mt-3 mb-2 " />
                    <br /> Credit Card </Link>
                </div>
                <div class="col-2" id="ic" style={{ backgroundColor: '#dcf0fa' }}  >
                  <Link to="/login">  <img src="assets/images/icon/loan.png" alt="" width="40" class=" mt-3 mb-2" />
                    <br /> Loan EMI </Link>
                </div>
                <div class="col-2" id="ic" style={{ backgroundColor: '#dcf0fa' }} >
                  <Link to="/login">  <img src="assets/images/icon/resto.png" alt="" width="40" class=" mt-3 mb-2" />
                    <br />Restaurants</Link>
                </div>
                <div class="col-2" id="ic" style={{ backgroundColor: '#dcf0fa' }} >
                  <Link to="/login">  <img src="assets/images/icon/edu.png" alt="" width="40" class=" mt-3 mb-2" />
                    <br />Education </Link>
                </div>
                <div class="col-2" id="ic" style={{ backgroundColor: '#dcf0fa' }} >
                  <Link to="/login">  <img src="assets/images/icon/travler.png" alt="" width="40" class=" mt-3 mb-2" />
                    <br />Travel </Link>
                </div>
                <div class="col-2" id="ic" style={{ backgroundColor: '#dcf0fa' }} >
                  <Link to="/login">  <img src="assets/images/icon/brand.png" alt="" width="40" class=" mt-3 mb-2" />
                    <br />Top Brands </Link>
                </div>
                <div class="col-2" id="ic" style={{ backgroundColor: '#dcf0fa' }} >
                  <Link to="/login"> <img src="assets/images/icon/see.png" alt="" width="40" class=" mt-3 mb-2" />
                    <br /> See All </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* offers */}
      <section className="mt-4">
        <Coffer />
      </section>
      {/*New Commercial Development */}
      <Res />
      <Footer />
    </>
  );
}
export default Home;