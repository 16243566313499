import axios from 'axios';

// Create an Axios instance using the base URL from the environment variable
const Api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Corrected line
  headers: {
    'Content-Type': 'application/json',
  },
});

export default Api;