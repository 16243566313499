import React, { useState, useEffect } from 'react';
import Api from '../components/Api';
import Unav from '../components/Unav';
import Footer from '../components/Footer';
import Code from '../components/Code';
import { Link } from 'react-router-dom';

// Component for rendering the complaint form
const ComplaintForm = ({ userDetail, remark, setRemark, handleSubmit, loading, error, success }) => (
  <form onSubmit={handleSubmit}>
    <div className="row">
      {/* Form Fields */}
      {['role', 'name', 'phone', 'email', 'tower', 'flat'].map((field, idx) => (
        <div key={idx} className="col-lg-6 col-12 mt-3 form-group">
          <div className="row">
            <div className="col-lg-4 col-12 mt-2">
              <label className="mt-2"><b>{field.charAt(0).toUpperCase() + field.slice(1)}</b></label>
            </div>
            <div className="col-lg-8 col-12 mt-2">
              {field === 'phone' ? (
                <div className="row">
                  <div className="col-4"><Code /></div>
                  <div className="col-8"><input type="text" className="w-100 form-control text-uppercase" value={userDetail[field] || ''} disabled /></div>
                </div>
              ) : (
                <input type="text" className="w-100 form-control text-uppercase" value={userDetail[field] || ''} disabled />
              )}
            </div>
          </div>
        </div>
      ))}
      {/* Additional Fields */}
      <div className="col-lg-6 col-12 mt-3 form-group"> 
        <div className="row">
          <div className="col-lg-4 col-12 mt-3"><label><b>Visitor Name</b></label></div>
          <div className="col-lg-8 col-12 mt-2">
           <input type="text" 
           placeholder='enter visitor name'
           className='form-control'
           name='visitorname'/>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-12 mt-3 form-group"> 		
        <div className="row">
          <div className="col-lg-4 col-12 mt-3"><label><b>Visitor Phone</b></label></div>
          <div className="col-lg-8 col-12 mt-2">
           <input type="text" 
             placeholder='enter visitor phone'
           className='form-control'
           name='visitorname'/>
          </div>
        </div>
      </div>
     
      <div className="col-lg-6 col-12 mt-3 form-group"> 
        <div className="row">
          <div className="col-lg-4 col-12 mt-3"><label><b>Person to Meet</b></label></div>
          <div className="col-lg-8 col-12 mt-2">
           <input type="text" 
             placeholder='enter person to meet'
           className='form-control'
           name='visitorname'/>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-12 mt-3 form-group"> 	
        <div className="row">
          <div className="col-lg-4 col-12 mt-3"><label><b>Visitor In Time</b></label></div>
          <div className="col-lg-8 col-12 mt-2">
           <input type="time" 
           className='form-control'
           name='visitorname'/>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-12 mt-3 form-group"> 	
        <div className="row">
          <div className="col-lg-4 col-12 mt-3"><label><b>Visitor Out Time</b></label></div>
          <div className="col-lg-8 col-12 mt-2">
           <input type="time"
           className='form-control'
           />
          </div>
        </div>
      </div>
      {/* Remarks */}
      <div className="col-lg-6 col-12 mt-3 form-group">
        <label><b>Remarks</b></label>
        <textarea className="form-control" value={remark} onChange={(e) => setRemark(e.target.value)}></textarea>
      </div>
    </div>
    <div className="row ">
      <div className="col-lg-3 col-12 mt-3 mb-3">
        <button className="bg-primary text-white form-control text-uppercase w-100" type="submit">
          {loading ? 'Loading...' : 'Submit'}
        </button>
      </div>
    </div>
    {/* Display success or error messages */}
    {error && <div className="alert alert-danger text-center p-2 mt-2">{error}</div>}
    {success && <div className="alert alert-success text-center p-2 mt-2">{success}</div>}
  </form>
);

// Component for rendering the visitor table
const VisitorTable = ({ visitors }) => (
  <table className="table table-bordered table-hover mt-4">
    <thead style={{ backgroundColor: '#f7f7f7' }}>
      <tr>
        <th>ID</th>
        <th>Tower No</th>
        <th>Flat Number</th>
        <th>Visitor Name</th>
        <th>Visitor Phone</th>
        <th>Person to Meet</th>
        <th>In Time</th>
        <th>Out Time</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      {visitors.length > 0 ? (
        visitors.map((visitor) => (
          <tr key={visitor.id}>
            <td>{visitor.id}</td>
            <td>{visitor.tower}</td>
            <td>{visitor.flatNumber}</td>
            <td>{visitor.name}</td>
            <td>{visitor.phone}</td>
            <td>{visitor.personToMeet}</td>
            <td>{visitor.inTime}</td>
            <td>{visitor.outTime}</td>
            <td>{visitor.status}</td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="9" className="text-center">No data available</td>
        </tr>
      )}
    </tbody>
  </table>
);

// Main Visitor Component
function Visitor() {
  const [visitors, setVisitors] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddComplaint, setShowAddComplaint] = useState(true);
  const [userDetail, setUserDetail] = useState({});
  const [remark, setRemark] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    // Fetch simulated visitor data
    setVisitors([
      { id: 1, tower: '22', flatNumber: 'A101', name: 'John Doe', phone: '1234567890', personToMeet: 'Mr. Smith', inTime: '10:00 AM', outTime: '11:00 AM', status: 'Completed' },
      { id: 2, tower: '20', flatNumber: 'B202', name: 'Jane Roe', phone: '0987654321', personToMeet: 'Mrs. Jane', inTime: '12:00 PM', outTime: '1:00 PM', status: 'Pending' },
    ]);
    // Fetch user data from API
    const fetchUserData = async () => {
      const phone = JSON.parse(localStorage.getItem('userDetails'))?.phone || '';
      if (phone) {
        try {
          const response = await Api.post(`/userinfo`, { phone });
          setUserDetail(response.data.userDetail);
        } catch (error) {
          setError('Failed to fetch user data.');
        }
      }
    };
    fetchUserData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');
    try {
      const response = await Api.post('/', {
        name: userDetail.name,
        phone: userDetail.phone,
        email: userDetail.email,
        role: userDetail.role,
        flat: userDetail.flat,
        tower: userDetail.tower,
        remark,
      });
      const result = response.data;
      result.message === 'Form submitted successfully' ? setSuccess(result.message) : setError(result.message || 'Submission failed');
      setRemark('');
    } catch (err) {
      setError(err.response?.data?.message || 'Submission failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="row">
        <Unav />
        <div className="col-lg-9 col-12">
          <div className="row">
            <div className="col-lg-4 col-6 bg-primary" id="alink">
              <Link className="text-white" onClick={() => setShowAddComplaint(true)}>Request Visitors Approval</Link>
            </div>
            <div className="col-lg-4 col-6 bg-danger" id="alink">
              <Link className="text-white" onClick={() => setShowAddComplaint(false)}>View Visitors Request</Link>
            </div>
          </div>
          <div className="row mt-2 me-2 ms-1">
            <div className="col-12 card p-3 mb-4">
              {showAddComplaint ? (
                <ComplaintForm
                  userDetail={userDetail}
                  remark={remark}
                  setRemark={setRemark}
                  handleSubmit={handleSubmit}
                  loading={loading}
                  error={error}
                  success={success}
                />
              ) : (
                <VisitorTable visitors={visitors} />
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Visitor;
