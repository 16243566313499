import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { Helmet } from 'react-helmet';
const Gym = () =>{

    return(
        <>
         <Helmet>
        <title>Housing Society Rules and Regulations for resident</title>
        <meta name="title" property="og:title" content="Housing Society Rules and Regulations for resident" />
        <meta name="description" content="Society is providing such amenities and facilities to the members to take a great experience e.g. swimming pool, gardens, club house, gym" />
        <meta property="og:description" content="Society is providing such amenities and facilities to the members to take a great experience e.g. swimming pool, gardens, club house, gym" />
       </Helmet>
        <Navbar />
   
      
        <section className="hero-section" id="section_1">
          <div className="section-overlay" />
          <div className="video-wrap">
            <img src="assets/images/gym rule.jpg" alt="" className="custom-video" id="imgym" />
          </div>
        </section>
        <section className="about-section">
          <div className="container">
            <h2 className="radiant mt-2">RULES AND REGULATIONS FOR GYM USERS</h2>
            <hr />
          </div>
        </section>
        <section>
          <div className="container">
            <h6 className="mt-5 mb-4">ATTIRE AND FOOTWEAR</h6>
            <p style={{textAlign: 'justify', fontWeight: 500}}>All gym users must be properly attired in standard workout
              clothes i.e. Tank
              tops, T-shirts, shorts,
              leotards with running or cross training shoes <b>(not use outside the gym) </b>while in exercise
              area. Swimsuits, <b>JEANS</b> and street clothes &amp; shoes are <b>NOT ALLOWED</b> Shirts and shorts
              must be
              worn at all times.
            </p>
            <br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>Any form of attire, which may
              cause possible infringement of safety to self or other gym user, or
              potential damage to the equipment, is prohibited. The management &amp; gym staff on duty reserve the
              right to decide on the suitability of the sporting attire worn by gym users. </p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>Dry, closed-top athletic shoes or sneakers
              must be worn
              at all times. <b>NO </b>dress shoes, sandals,
              boots, <b>SLIPPERS</b>, open-toed shoes, OR <b>BARE FEET</b> are permitted. </p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}><b>Outside</b> footwear is strictly prohibited
              on the
              treadmills or
              any other machines so bring <b>along a
                proper gym shoe for your workout.</b> </p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>But here’A personal towel must be used at all
              times. Gym users are not allowed to share towels. Please
              bring your own towel and wipe your sweat off the seats or machines that you have used. Other users
              wish to use the seats or/and machines in the gym without your sweat. </p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>· Wear clean and dry clothes while working
              out.
              Inappropriate
              body odor is offensive. </p><br />
            <h5 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3"><b>GYM
                EQUIPMENT
                <b /></b></h5><b><b>
                <p style={{textAlign: 'justify', fontWeight: 500}}>All gym users are expected to practice good
                  hygiene and
                  gym etiquette.
                </p><br />
                <p style={{textAlign: 'justify', fontWeight: 500}}>All gym users must observe the instructions
                  and safety
                  precautions pertaining to the use of the
                  exercise equipment. If in doubt, gym users are required to consult the officer on duty.
                </p><br />
                <p style={{textAlign: 'justify', fontWeight: 500}}>Use discretion when you talk to others while
                  they are
                  using equipment. Especially those using
                  cardio equipment and free weights. Keep conversation short and soft as not to disturb other users’
                  concentration and workout. </p><br />
                <h5 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3"><b>Cardio
                    Section
                    <b /></b></h5><b><b>
                    <p style={{textAlign: 'justify', fontWeight: 500}}>Do not monopolize the machines. <strong>Cardio
                        machines</strong> are restricted to <strong>20 minutes</strong> per use during
                      <strong>peak hours</strong>. All other equipment shall be shared among gym users at all times.
                    </p><br />
                    <p style={{textAlign: 'justify', fontWeight: 500}}>·<b> RETURN SAFETY KEY</b> to the counter
                      <b>IMMEDIATELY</b> after use
                    </p><br />
                    <p style={{textAlign: 'justify', fontWeight: 500}}>· Do not use weights while on cardio
                      equipment. </p><br />
                    <h5 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3"><b>Weights
                        Section
                        <b /></b></h5><b><b>
                        <p style={{textAlign: 'justify', fontWeight: 500}}><b>DO NOT SLAM, DROP, CLANG, OR THROW
                            DUMBBELLS OR FREE
                            WEIGHTS</b> on the floor when
                          you are finished.
                        </p><br />
                        <p style={{textAlign: 'justify', fontWeight: 500}}>· <b>DO NOT LEAVE EQUIPMENT LYING AROUND</b>
                          someone could
                          trip over it. Replace equipment to
                          its original state and location after use. </p><br />
                        <p style={{textAlign: 'justify', fontWeight: 500}}>· <b>ALWAYS USE THE COLLARS</b> that prevent
                          weights from
                          falling off the barbells. </p><br />
                        <p style={{textAlign: 'justify', fontWeight: 500}}>· Please stand <b>3 FEET AWAY</b> from the
                          glass mirror
                          for those who are carrying the dumbbells and
                          free weights.
                        </p><br />
                        <p style={{textAlign: 'justify', fontWeight: 500}}><b>• At All Times Workout At The Olympic Flat
                            Bench
                            Requires To Workout In PAIRS (2
                            Users Assisting Each Other).</b></p><br />
                        <p style={{textAlign: 'justify', fontWeight: 500}}>· RETURN ALL GYM EQUIPMENT, BARBELLS,
                          DUMBBELLS AND FREE
                          WEIGHTS TO THEIR
                          DESIGNATED PLACES AFTER USE.</p><br />
                        <p style={{textAlign: 'justify', fontWeight: 500}}> Ensure proper safety when using equipment. If
                          you do not
                          have a workout partner at first, we
                          strongly recommend trying to find someone with similar goals and interests to work out with you. If
                          you aren't working with a partner at a gym, either ask a staff member or someone who looks
                          experienced for a quick "spot". Be sure you and your spotter have a plan so that each of you knows
                          exactly what the other will do in case you need assistance. It is also important that you know how
                          to
                          correctly spot someone to assure his or her safety. When spotting someone, always be prepared to
                          give a little assistance when they experience muscle fatigue (cannot complete the rep on their own).
                        </p><br />
                        <p style={{textAlign: 'justify', fontWeight: 500}}>· Misusing the equipment can result in injury
                          and/or
                          damage to the equipment; follow directions
                          and instructions. Ask for assistance from the counter staff if you are unfamiliar with equipment.
                        </p><br />
                        <p style={{textAlign: 'justify', fontWeight: 500}}>· Gym users will be held responsible for any
                          damages
                          caused to the equipment by them. They are
                          also responsible for their own safety and any injuries incurred while using the equipment so please
                          be extra carefull. </p><br />
                        <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify', textDecoration: 'underline'}} className="my-3">PROHIBITION
                        </h6>
                        <p style={{textAlign: 'justify', fontWeight: 500}}>· Food and drinks (except for plain water) is
                          not allowed
                          at the workout area or dance studio at
                          any time. Water consumed during workout must be in a container with a lid. Any spills must be
                          cleaned immediately. </p><br />
                        <p style={{textAlign: 'justify', fontWeight: 500}}><b>· No smoking or alcohol on premises.</b>
                        </p><br />
                        <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify', textDecoration: 'underline'}} className="my-3">PHYSICAL CONDITION
                        </h6>
                        <p style={{textAlign: 'justify', fontWeight: 500}}>· All gym users are advised to seek medical
                          consultation
                          and clearance before embarking on any
                          exercise programs. </p><br />
                        <p style={{textAlign: 'justify', fontWeight: 500}}> <b>Be sure</b> to always <b>integrate
                            warm-ups,
                            stretching, and cooling- down</b> into your program. This
                          will reduce your risk of injury by increasing your blood flow and preparing your muscles for the
                          workout. Proper use of all machines &amp; free weights is important not only to work your muscles
                          correctly, but also to prevent injury. </p><br />
                        <p style={{textAlign: 'justify', fontWeight: 500}}>Always do your exercises through a full range
                          of motion in
                          a slow, controlled manner.
                          <b>When using all machines &amp; free weights users must not let the weights fall but to release them
                            slowly in a controlled manner.</b> Failing to do so will make noise and spoil the equipment.
                          Users not
                          following the proper use of the equipment as stated above will be given stern warning before asking
                          user to leave the gymnasium.
                        </p><br />
                        <p style={{textAlign: 'justify', fontWeight: 500}}>· When beginning a new weight-lifting program
                          or any time
                          you try a new exercise always start out
                          using light weights. It is far better to start out too light than too heavy. This is to avoid
                          possible
                          injuries. </p><br />
                        <p style={{textAlign: 'justify', fontWeight: 500}}>· Facing muscle fatigue with challenging
                          weights is not a
                          practical objective in your first few weeks
                          of workout. When trying a new lift or starting a new routine, the objective is to practice and
                          perfect
                          your technique, and to learn how to concentrate on the muscle you are exercising and your
                          breathing.
                        </p><br />
                        <p style={{textAlign: 'justify', fontWeight: 500}}>· Proper breathing is essential in
                          weightlifting. If you
                          hold your breath while lifting a weight, you
                          run the risk of raising your blood pressure and starving your brain of oxygen. You should try to
                          exhale
                          during the "positive," or main exertion phase, and inhale during the "negative," the phase in which
                          you resist and come back slowly. If this becomes too confusing or takes away from your
                          concentration on the lift, don't worry about it--just breathe normally. </p><br />
                        <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify', textDecoration: 'underline'}} className="my-3">Personal Belongings
                        </h6>
                        <p style={{textAlign: 'justify', fontWeight: 500}}>· All Gym users are required to keep their
                          belongings in
                          the open shelves provided. </p><br />
                        <p style={{textAlign: 'justify', fontWeight: 500}} /><br />
                        <p style={{textAlign: 'justify', fontWeight: 500}}><b>· NO BAGS ARE ALLOWED ON THE WORKOUT
                            FLOOR.</b></p><br />
                        <p style={{textAlign: 'justify', fontWeight: 500}}>· Staff on duty are not responsible for
                          personal
                          belongings of gym and dance studio users. Please
                          do not bring any valuables to the gymnasium or/and the dance studio as the university/staff will not
                          be responsible for any lost or theft of such items/valuables. </p><br />
                        <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify', textDecoration: 'underline'}} className="my-3">GENERAL
                        </h6>
                        <p style={{textAlign: 'justify', fontWeight: 500}}><b>· No personal notices, advertising or other
                            literature,
                            to be posted or distributed at the
                            gymnasium/dance studio.</b></p><br />
                        <p style={{textAlign: 'justify', fontWeight: 500}}> Gym users should refrain from talking loudly
                          or indulge
                          in noisy activities that may annoy or
                          distract other users and staff on duty (e.g. dropping weights forcefully) or any other form of
                          conduct
                          that is deemed inappropriate by the management &amp; staff of the Gym. </p><br />
                        <p style={{textAlign: 'justify', fontWeight: 500}}>· <b>PLEASE DO NOT TOUCH THE STEREO OR STAFF
                            COMPUTER.</b>
                          Adjustments to the stereo, fans, or
                          other equipment is to be made by staff only. </p><br />
                        <p style={{textAlign: 'justify', fontWeight: 500}}>· While reasonable safety precautions have
                          been taken in
                          relation to the use of the gym, please
                          note that you use the Gym at your own risk. Neither UTAR, nor its staff or agents shall be liable
                          for
                          any loss or damage to property or death or personal injury (save for death or personal injury
                          resulting from the negligence of UTAR, its staff or agents), however arising from your use of the
                          Gym.</p><br />
                        <p style={{textAlign: 'justify', fontWeight: 500}}>· All gym users are required to comply with
                          the
                          instructions given by the gym management &amp; staff
                          on duty. The management &amp; staff on duty reserve the right to refuse a person entry into the gym,
                          and / or if the person is already in the gym, to prohibit the person from further usage of the gym
                          by
                          instructing the person to leave the gym immediately, if in the opinion of the management &amp; staff on
                          duty, the person has violated any of the rules and regulations. <b>INDIVIDUALS NOT ADHERING TO
                            THESE RULES &amp; REGULATIONS MAY BE ASKED TO LEAVE</b> or/and the management reserves the right
                          to bring the gym users who do not adhere to the rules and regulations to respective disciplinary
                          board.
                        </p><br />
                        <p style={{textAlign: 'justify', fontWeight: 500}}>· USERS MUST EXIT FITNESS CENTER PROMPTLY
                          BEFORE CLOSING
                          TIME </p><br />
                        <p style={{textAlign: 'justify', fontWeight: 500}}><b>* UTAR RESERVES THE RIGHT TO ADD, DELETE,
                            AMEND OR VARY
                            THE ABOVE RULES AND
                            REGULATIONS AT ITS OWN DISCRETION AT ANY TIME AS IT DEEMS FIT, WITHOUT HAVING TO
                            INFORM ANY GYM USERS.</b></p><br />
                      </b></b></b></b></b></b></div><b><b>
            </b></b></section>
            <Footer/>
        </>
    );
}
export default Gym;