import React, { useEffect, useState } from "react";
import Unav from "../components/Unav";
import Footer from "../components/Footer";
import Api from "../components/Api";
const Uinbox = () => {
  const [emailDetails, setEmailDetails] = useState([]);
  const [message, setMessage] = useState("");
  useEffect(() => {
    const fetchUserData = async () => {
      const phone = JSON.parse(localStorage.getItem("userDetails"))?.phone || "";
      if (phone) {
        try {
          const response = await Api.post(`/userinfo`, { phone });
          const result = response.data;
          if (result.message === "User Detail Fetch Successfully") {
            setEmailDetails(result.userDetail.emaildetail || []);
          } else {
            setMessage("Failed to fetch user data.");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setMessage("An error occurred while fetching user data.");
        }
      }
    };
    fetchUserData();
  }, []);
  return (
    <>
      
      <div className="row">
        <Unav />
        <div className="col-lg-9 col-12">
          <div className="row mt-3 me-2 ms-1">
            <div className="col mb-4 shadow-sm" style={{ overflowX: "auto" }}>
             
              <table className="table table-bordered table-hover " >
                <thead className="table-primary text-center">
                  <tr className="table-row">
                    <th scope="row" >S.N.</th>
                    <th>DATE & TIME</th>
                    <th>TITLE</th>
                    <th>STATUS</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {emailDetails.length > 0 ? (
                    emailDetails.map((detail, index) => (
                      <tr key={detail.id} className="table-row">
                        <th scope="row" className="text-center">{index + 1}</th>
                          <td className="text-center">
                            {new Date(detail.updated_at).toLocaleString()}</td>
                      
                        <td>{detail.title}</td>
                        <td className="text-center">
                          {detail.current_status}
                        </td>
                        <td>{detail.user_comments || "No attachment"}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {message && <div className="alert alert-warning">{message}</div>}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Uinbox;
