import React from "react"
import Unav from "../components/Unav";
import Footer from "../components/Footer";

const Uvoting = () => {
  return (
    <>

      <div className="row">
        <Unav />
        <div className="col-lg-9 col-12">

          <div className="col-8 ms-3 me-3 mt-4">
            <h5>Online Voting | Make a Poll in Minutes</h5> <hr />
            <span className="mt-3">
              Polls that only ask relevant questions. Personalize the user journey. Get more responses. Easily create a great user experience.
            </span>
            <br /> <br />

            <h6>Online Voting: How it works </h6>
            <span className="text-danger">[Only Your Society Persident/General Secretary or EC (Election Commission) Team has rights to launch your online election]
            </span><br /><br />
            This is how you can create an online election in three steps: create online ballots, generate your electoral roll and launch your online election. Let's go!
          </div>


        </div>
      </div>
      <Footer />
    </>
  );
}
export default Uvoting;
