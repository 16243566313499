import React from "react";
import { Routes, Route } from 'react-router-dom';
import Home from "./pages/Home";
import Swimming from "./pages/Swimming";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Verify from "./pages/Verify";
import Pending from "./pages/Pending";
import Offer from "./pages/Offer";
import ForgotPwd from "./pages/ForgotPwd";
import NotFoundPage from "./pages/NotFoundPage";
import Gym from "./pages/Gym";
import Homepost from "./components/homepost";

import Dashboard from "./users/Dashboard";
import Uprofile from "./users/Uprofile";
import Uevent from "./users/Uevent";
import Urules from "./users/Urules";
import Ucom from "./users/Ucom";
import Uinbox from "./users/Uinbox";
import Ucard from "./users/Ucard";
import Urfid from "./users/Urfid";
import Uparking from "./users/Uparking";
import Upet from "./users/Upet";
import Uhall from "./users/Uhall";
import Ustaff from "./users/Ustaff";
import Uvoting from "./users/Uvoting";
import Uexit from "./users/Uexit";
import Updatepwd from "./users/Updatepwd";
import Uhome from "./users/Uhome";
import Bill from "./users/Bill";
import Uoffer from "./users/Uoffer";
import PostDetails from "./components/Postdetails";
import Report from "./users/Report";
import Visitor from "./users/Visitor";
import Complaint from "./users/Complaint";


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/swimming-pool-rules&regulations" element={<Swimming />} />
        <Route path="/gym-rules&regulations" element={<Gym />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/security-verifaction" element={<Verify />} />
        <Route path="/offer" element={<Offer />} />
        <Route path="/account-approval" element={<Pending />} />
        <Route path="/rules-&-regulations" element={<Homepost />} />
        {/* Private Routes */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/home" element={<Uhome />} />
        <Route path="/home/offer" element={<Uoffer />} />
        <Route path="/my-account" element={<Uprofile />} />
        <Route path="/society-events" element={<Uevent />} />
        <Route path="/rules-regulations" element={<Urules />} />
        <Route path="/communication-update" element={<Ucom />} />
        <Route path="/inbox" element={<Uinbox />} />
        <Route path="/access-card" element={<Ucard />} />
        <Route path="/rfid-tag" element={<Urfid />} />
        <Route path="/parking-sticker" element={<Uparking />} />
        <Route path="/pet-register" element={<Upet />} />
        <Route path="/booking-society-area" element={<Uhall />} />
        <Route path="/staff-report" element={<Ustaff />} />
        <Route path="/voting" element={<Uvoting />} />
        <Route path="/forgot-password" element={<ForgotPwd />} />
        <Route path="/exit-management" element={<Uexit />} />
        <Route path="/change-password" element={<Updatepwd />} />
        <Route path="/bill-payment" element={<Bill />} />
        <Route path="/home/rules-&-regulations" element={<PostDetails />} />
        <Route path="/bill-report" element={<Report />} />
        <Route path="/visitor-management" element={<Visitor />} />
        <Route path="/complaint-management" element={<Complaint />} />
        <Route path="*" element={<NotFoundPage />} />
       
      </Routes>

    </div>
  );
}
export default App;
