import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Api from "../components/Api";
import Unav from "../components/Unav";
import Footer from "../components/Footer";
const Ucom = () => {
  const [adminPosts, setAdminPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  useEffect(() => {
    const fetchAdminPosts = async () => {
      try {
        const response = await Api.get("/user-communication-update-list");
        setAdminPosts(response.data.data);
      } catch (error) {
        console.error("Error fetching admin posts:", error);
      }
    };
    fetchAdminPosts();
  }, []);
  const handlePostClick = (post) => {
    setSelectedPost(post);
  };
  const handleCloseModal = () => {
    setSelectedPost(null);
  };
  return (
    <>
      
      <div className="row">
        <Unav />
        <div className="col-lg-9 col-12">
            <div className="row">
            <div className='col-12 mb-2 bg-info'>
            <h4 className="radiant p-3">COMMUNICATION UPDATE</h4>
            </div>
            </div>
         
            <div className="container mb-5" >
            
              <div className="bg-white rounded p-3 mb-5">
            <div className="col-lg-12  col-12 " >
               <div className="row" >
                <div className="col-12" >
                  <table className="table table-bordered " >
                    <thead className="">
                      <tr>
                        <th className="text-center">Date</th>
                        <th>Title</th>
                      </tr>
                    </thead>
                    <tbody className=""  >
                      {adminPosts.map((post) => (
                        <tr key={post.id} onClick={() => handlePostClick(post)} style={{ cursor: "pointer" }} >
                          <td className="text-center">
                            <a id="a3" className="text-black" >{post.date || "dd/mm/yy"}</a></td>
                          <td className="">
                            <a  id="a3">{post.name}</a></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="row justify-content-end mt-3">
                    <div className="col-lg-5 col-12 ">
                    <nav aria-label="Page navigation example text-center w-100">
              <ul class="pagination">
              <li class="page-item"><a class="page-link" href >Previous</a></li>
                <li class="page-item"><a class="page-link" href >1</a></li>
               <li class="page-item"><a class="page-link" href >2</a></li>
               <li class="page-item"><a class="page-link" href >3...</a></li>
               <li class="page-item"><a class="page-link" href >Next</a></li>
              </ul>
             </nav>
                     
                      
                    </div>
                  </div>
              </div>
            </div>
             
              </div>
            </div>

            
          </div>
      </div>

    
      <Footer />
      {/* Modal */}
      {selectedPost && (
        <div className="modal show fade d-block" role="dialog" style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header bg-success">
                <div className="modal-title text-white" style={{
                  fontSize: '25px'
                }} > COMMUNICATION UPDATE </div>
                <button type="button" className="btn btn-danger" onClick={handleCloseModal} aria-label="Close">
                  <span className="ms-2 me-2"> Close </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 justify-contant-center">
                    <img src={selectedPost.photo} alt={selectedPost.name} className="w-100 mb-3 card" style={{
                      height: '300px',
                      objectFit: 'cover',
                    }} />
                  </div>
                  <div className="col-12 mt-3">
                    <h5 className="radiant"> {selectedPost.name} </h5> <hr />
                  </div>
                </div>
                <div>{selectedPost.description}</div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default Ucom;
