import React, { useState, useEffect } from "react";
import Api from "../components/Api"; 
import Unav from "../components/Unav";
import Footer from "../components/Footer";
import Code from "../components/Code";
function Uexit() {
  const [userDetails, setUserDetails] = useState({});
  const [message, setMessage] = useState("");
  useEffect(() => {
    const fetchUserData = async () => {
      const phone = JSON.parse(localStorage.getItem("userDetails"))?.phone || "";
      if (phone) {
        try {
          const response = await Api.post(`/userinfo`, { phone });
          const result = response.data;
          if (result.message === "User Detail Fetch Successfully") {
            setUserDetails(result.userDetail);
          } else {
            setMessage("Failed to fetch user data.");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setMessage("An error occurred while fetching user data.");
        }
      }
    };
    fetchUserData();
  }, []);
  return (
    <>
     
      <div className="row">
        <Unav />
        <div className="col-lg-9 col-12">
          <div className="row mt-2 me-2 ms-1">
            <div className="col-12 card p-3 mb-4">
              <div className="row">
                <div className="col-12">
                  <h5 className="radiant">Assets Handover to Maintenance Teams</h5>
                  <hr />
                </div>
              </div>
              {/* User details form */}
              <div className="row">
                {/* Role */}
                <div className="col-lg-6 col-12 mt-1 form-group">
                  <div className="row">
                    <div className="col-lg-4 col-12 mt-2">
                      <label className="mt-2"><b>I am an/a</b> </label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">
                      <input type="text" className="w-100 form-control text-uppercase" value={userDetails.role || ''} disabled />
                    </div>
                  </div>
                </div>
                
                {/* Name */}
                <div className="col-lg-6 col-12 mt-1 form-group">
                  <div className="row">
                    <div className="col-lg-4 col-12 mt-2">
                      <label><b>Name</b> <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">
                      <input className="w-100 form-control text-uppercase" type="text" value={userDetails.name || ''} disabled />
                    </div>
                  </div>
                </div>
                
                {/* Phone */}
                <div className="col-lg-6 col-12 mt-3 form-group">
                  <div className="row">
                    <div className="col-lg-4 col-12 mt-2">
                      <label><b>Phone</b> <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">
                      <div className="row">
                        <div className="col-4">
                          <Code />
                        </div>
                        <div className="col-8">
                          <input className="w-100 form-control text-uppercase" type="text" value={userDetails.phone || ''} disabled />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* Email */}
                <div className="col-lg-6 col-12 mt-3 form-group">
                  <div className="row">
                    <div className="col-lg-4 col-12 mt-2">
                      <label><b>E-Mail ID</b> <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">
                      <input className="form-control text-uppercase" type="text" value={userDetails.email || ''} disabled />
                    </div>
                  </div>
                </div>
                
                {/* Flat Number */}
                <div className="col-lg-6 col-12 mt-3 form-group">
                  <div className="row">
                    <div className="col-lg-4 col-12 mt-3">
                      <label><b>Flat Number</b> <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">
                      <input className="form-control text-uppercase" type="text" value={userDetails.flat || ''} disabled />
                    </div>
                  </div>
                </div>
                
                {/* Tower */}
                <div className="col-lg-6 col-12 mt-3 form-group">
                  <div className="row">
                    <div className="col-lg-4 col-12 mt-2">
                      <label><b>Tower</b> <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">
                      <input className="form-control text-uppercase" type="text" value={userDetails.tower || ''} disabled />
                    </div>
                  </div>
                </div>
                {/* Attachment */}
                <div className="col-lg-6 col-12 mt-3 form-group">
                  <div className="row">
                    <div className="col-lg-4 col-12 mt-2">
                      <label><b>Attachment</b> <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-lg-8 col-12 mt-2">
                      <input className="form-control" type="file" />
                    </div>
                  </div>
                </div>
                {/* Parking Sticker issued */}
                <div className="col-lg-6 col-12 mt-3 form-group">
                  <div className="row">
                    <div className="col-lg-6 col-12 mt-3">
                      <label><b>Parking Sticker issued</b> <span className="text-danger">*</span></label>
                    </div>
                    <div className="col-lg-6 col-12 mt-2">
                      <div className="row text-center">
                        <div className="col-6">
                          <label>
                            <input type="radio" name="parkingSticker" value="yes" required /> Yes
                          </label>
                        </div>
                        <div className="col-6">
                          <label>
                            <input type="radio" name="parkingSticker" value="no" required /> No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* RFID TAG */}
                <div className="col-lg-6 col-12 mt-3 form-group">
                  <div className="row">
                    <div className="col-lg-6 col-12 mt-3">
                      <label><b>RFID TAG [Boom Barrier]</b></label>
                    </div>
                    <div className="col-lg-6 col-12 mt-2">
                      <div className="row text-center">
                        <div className="col-6">
                          <label>
                            <input type="radio" name="rfidTag" value="yes" required /> Yes
                          </label>
                        </div>
                        <div className="col-6">
                          <label>
                            <input type="radio" name="rfidTag" value="no" required /> No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Access Card */}
                <div className="col-lg-6 col-12 mt-3 form-group">
                  <div className="row">
                    <div className="col-lg-6 col-12 mt-3">
                      <label><b>Access Card</b></label>
                    </div>
                    <div className="col-lg-6 col-12 mt-2">
                      <div className="row text-center">
                        <div className="col-6">
                          <label>
                            <input type="radio" name="accessCard" value="yes" required /> Yes
                          </label>
                        </div>
                        <div className="col-6">
                          <label>
                            <input type="radio" name="accessCard" value="no" required /> No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Submit button */}
              <div class="row justify-content-end"><div class="col-lg-3 col-12 mt-4 mb-3"><input class="bg-primary text-white form-control me-5  " type="submit" /></div></div>
              {/* Error or success message */}
              {message && (
                <div className="row mt-3">
                  <div className="col-12">
                    <p className="text-danger">{message}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Uexit;
