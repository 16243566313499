import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Unav from "../components/Unav";
import Footer from "../components/Footer";
import Api from "../components/Api";

const Urules = () => {
  const [rulesPosts, setrulesPosts] = useState([]);
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    const fetchrulesPosts = async () => {
      try {
        const response = await Api.get("user-rules-regulations");
        setrulesPosts(response.data.data);
      } catch (error) {
        console.error("Error fetching rules posts:", error);
      }
    };

    fetchrulesPosts();
  }, []);

  const handlePostClick = (post) => {
    navigate('/home/rules-&-regulations', { state: { selectedPost: post } });
  };

  return (
    <>
      <div className="row">
        <Unav />
        <div className="col-lg-9 col-12">
          <div className="row">
            <div className='col-12 mb-2 bg-info'>
              <h4 className="radiant p-3">Society Rules & Regulations For Residents</h4>
            </div>
          </div>

          <div className="container mb-5" >

            <div className="bg-white rounded p-3 mb-5">
              <div className="col-lg-12  col-12 " >
                <div className="row" >
                  <div className="col-12" >
                    <table className="table table-bordered " >
                      <thead className="">
                        <tr>
                          <th className="text-center">Date</th>
                          <th>Title</th>
                        </tr>
                      </thead>
                      <tbody className=""  >
                        {rulesPosts.map((post) => (
                          <tr key={post.id} onClick={() => handlePostClick(post)} style={{ cursor: "pointer" }} >
                            <td className="text-center">
                              <a id="a3" className="text-black" >{post.date || "dd/mm/yy"}</a></td>
                            <td className="">
                              <a id="a3">{post.name}</a></td>
                          </tr>
                        ))}
                      </tbody>


                    </table>

                  </div>

                </div>
              </div>
              <div className="row justify-content-end mt-3">
                <div className="col-lg-5 col-12 ">
                  <nav aria-label="Page navigation example text-center w-100">
                    <ul class="pagination">
                      <li class="page-item"><a class="page-link" href >Previous</a></li>
                      <li class="page-item"><a class="page-link" href >1</a></li>
                      <li class="page-item"><a class="page-link" href >2</a></li>
                      <li class="page-item"><a class="page-link" href >3...</a></li>
                      <li class="page-item"><a class="page-link" href >Next</a></li>
                    </ul>
                  </nav>


                </div>
              </div>

            </div>
          </div>


        </div>
      </div>

      <Footer />
    </>
  );
}

export default Urules;
